import { useChatData } from "@context/chat";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Box, Flex } from "@styled-system/jsx";
import { CircularCounter, Separator, Text } from "doowii-ui";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  chatCount: number;
}
const ThreadListItemRaw: React.FC<Props> = ({ id, title, chatCount }) => {
  const navigate = useNavigate();

  const { selectThread } = useChatData();

  return (
    <button
      aria-label="thread-list-button"
      className={css({
        w: "full",
        p: "ld",
        borderRadius: "xl",
        background: "white",
        borderWidth: 1,
        borderColor: "base.lightGray",
        shadow: "regular",
        cursor: "pointer",
      })}
      onClick={async () => {
        await selectThread(id);
        navigate(`${id}`);
      }}
      type="button"
    >
      <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
        <Text level={4}>{title}</Text>
        <FontAwesomeIcon icon={faArrowRight} />
      </Flex>
      <Separator className={css({ my: "md" })} />
      <Flex css={{ alignItems: "center", gap: "sm" }}>
        <CircularCounter counter={chatCount} />
        <Text level={3}>
          {chatCount > 1 ? <Trans>Chats</Trans> : <Trans>Chat</Trans>}{" "}
          <Trans> inside this thread</Trans>
        </Text>
      </Flex>
    </button>
  );
};

export const ThreadListItem = React.memo(ThreadListItemRaw);
