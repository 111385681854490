import { useQuery } from "@tanstack/react-query";
import { fetchChartData } from "../api/retriever";
import { ParentDocTypeEnum } from "../api/retriever.i";
import { ChartConfig } from "@doowii-types/chart";
import { DataVizTypes } from "@doowii-types/viz";

interface UseChartQueryProps {
  sqlQuery: string;
  chartConfig: ChartConfig;
  docId?: string;
  parentDocId?: string;
  type: ParentDocTypeEnum;
}

const useChartQuery = ({ sqlQuery, chartConfig, docId, parentDocId, type }: UseChartQueryProps) => {
  const enabled = chartConfig?.suggestion !== DataVizTypes.TABLE;
  return useQuery({
    queryKey: ["chartData", chartConfig.columns, chartConfig.suggestion, chartConfig.column_aggregations, chartConfig.column_grouping, docId, parentDocId, type, sqlQuery],
    queryFn: () => {
      if (!chartConfig || !docId) {
        return Promise.reject(new Error("Invalid query parameters"));
      }
      return fetchChartData({
        docId,
        parentDocId,
        parentDocType: type,
        chartConfig,
      });
    },
    enabled: Boolean(chartConfig) && Boolean(docId) && enabled,
  });
};

export { useChartQuery };
