import { useModalClose } from "@hooks/useModalClose";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { sendContactRequest, sendDemoRegisterRequest } from "@services/webserver/emails";
import { css } from "@styled-system/css";
import { verifyEmailDomain } from "@utils/verifyEmailDomain";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  InputField,
  Textarea,
  useToast,
} from "doowii-ui";
import { useState } from "react";

interface Props {
  isOpen: boolean;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const RequestDemoDialog: React.FC<Props> = ({ isOpen, setIsOpen, triggerId }) => {
  useModalClose(isOpen, triggerId);
  const { _ } = useLingui();
  const { toast } = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [orgName, setOrgName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSend = async () => {
    setLoading(true);
    if (email.trim().length < 1) {
      setErrorMessage(_(msg`Email is required`));
      return;
    } else {
      try {
        await sendDemoRegisterRequest({
          email,
          org_name: orgName,
          message,
        });
        toast({
          title: _(msg`Access request sent successfully`),
          status: "success",
        });
      } catch (error) {
        toast({
          title: _(msg`Error sending access request. Please try again!`),
          status: "error",
        });
      }
      setIsOpen(false);
    }

    setLoading(false);
  };

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>
            <Trans>Request Access to Doowii</Trans>
          </DialogTitle>
          <DialogDescription>
            <Trans>
              It looks like you're using a non-eligible email domain. Doowii is currently available
              for users with .edu, .org, or .gov email addresses. If you’d like to explore how
              Doowii can be used with your own data, please provide your details below to request
              access.
            </Trans>
          </DialogDescription>
        </DialogHeader>
        <DialogBody className={css({ display: "flex", flexDirection: "column", gap: "ld" })}>
          <InputField
            disabled={loading}
            errorText={errorMessage}
            label={_(msg`Your Email`)}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={_(msg`Enter email`)}
            type="text"
            value={email}
          />
          <InputField
            disabled={loading}
            label={_(msg`Your Organization Name`)}
            onChange={(e) => setOrgName(e.target.value)}
            placeholder={_(msg`Enter organization name`)}
            type="text"
            value={orgName}
          />
          <Textarea
            label={_(msg`Optional Message`)}
            onChange={(e) => setMessage(e.target.value)}
            placeholder={_(
              msg`Let us know more about your school and what you'd like to learn about Doowii`
            )}
            value={message}
          />
        </DialogBody>
        <DialogFooter className={css({ borderTop: "none", p: "xl2", gap: "sm" })}>
          <Button loading={loading} onClick={handleSend} size="large" variant="primary">
            <Trans>Send access request</Trans>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { RequestDemoDialog };
