import { ParentDocTypeEnum } from "@api/retriever.i";
import { ChatExplanation } from "@components/ChatContainer";
import { DynamicChartRenderer } from "@components/DynamicChartRenderer";
import { useChatData } from "@context/chat";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/index";
import { faClipboard, faDownload, faGripLinesVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { shareResult } from "@services/webserver/emails";
import { css } from "@styled-system/css";
import { Flex, HStack, VStack } from "@styled-system/jsx";
import { token } from "@styled-system/tokens";
import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Heading,
  InputField,
  LoadingLogo,
  Logo,
  Separator,
  useToast,
} from "doowii-ui";
import { toBlob, toPng } from "html-to-image";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

interface ShareResultDialogProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  setIsShareResultDialogOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const ShareResultDialog: React.FC<ShareResultDialogProps> = ({
  result,
  parentDocId,
  parentDocType,
  setIsShareResultDialogOpen,
  isOpen,
}) => {
  const { _ } = useLingui();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState("");
  const { fetchSummaryFromPin, loading: loadingResultData } = useChatData();
  const ref = useRef<HTMLDivElement>(null);
  const { toast } = useToast();

  useEffect(() => {
    if (!result.id) {
      return;
    }
    if ((result as unknown as Pin).answer) {
      setSummary((result as unknown as Pin).answer);
      return;
    }

    (async () => {
      const chatSummary = await fetchSummaryFromPin(result as unknown as Pin);
      setSummary((chatSummary as unknown as string) || "");
    })();
  }, [result, fetchSummaryFromPin]);

  const filter = (node: HTMLElement | Node) => {
    const exclusionClasses = ["panel-resize-handle", "column-controls"];
    return !exclusionClasses.some(
      (classname) => node instanceof HTMLElement && node.classList.contains(classname)
    );
  };

  const handleShare = async () => {
    setLoading(true);

    try {
      const dataUrl = await toPng(ref.current, { filter });

      // Remove the data URL prefix
      const base64Data = dataUrl.split(",")[1];

      const shareRequest = {
        recipient_email: email,
        result_image: base64Data,
      };

      const response = await shareResult(shareRequest);

      if (response && response.status === 200) {
        toast({
          title: _(msg`Email sent successfully`),
          status: "success",
        });
      } else {
        throw new Error("Failed to share result");
      }
    } catch (error) {
      console.error(error);
      toast({
        title: _(msg`Error sharing result`),
        description: _(
          msg`An error occurred while sharing the result. Check the email address and try again.`
        ),
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageCopy = useCallback(async () => {
    if (ref.current === null) {
      return;
    }

    try {
      const blob = await toBlob(ref.current, { filter });
      const data = [new window.ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data);
      toast({
        title: _(msg`Image copied to clipboard`),
        status: "success",
      });
    } catch (err) {
      console.error(err);
      toast({
        title: _(msg`Error copying image`),
        description: _(msg`An error occurred while copying the image`),
        status: "error",
      });
    }
  }, [ref, toast, _]);

  const handleImageDownload = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current, { filter })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${result.title}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: _(msg`Error downloading image`),
          description: _(msg`An error occurred while downloading the image`),
          status: "error",
        });
      });
  }, [ref, toast, _, result.title]);

  const isLoadingContent = loadingResultData || !summary.length;

  return (
    <Dialog onOpenChange={setIsShareResultDialogOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} variant="fullScreen">
        <DialogHeader>
          <DialogTitle style={{ margin: "0" }}>
            <Trans>Share result</Trans>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className={css({ width: "100%" })}>
          {isLoadingContent ? (
            <LoadingLogo />
          ) : (
            <VStack gap="md">
              <Card
                css={css.raw({
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                  gradientBorder: "primary",
                  padding: 0,
                  gap: 0,
                })}
                ref={ref}
              >
                <Flex justify="space-between" width="full">
                  <Heading css={css.raw({ alignContent: "center", paddingLeft: "md" })} level={2}>
                    {result.title}
                  </Heading>
                  <Logo className={css({ display: "flex", alignItems: "center" })} />
                </Flex>
                <PanelGroup
                  className={css({
                    borderTop: "1px solid token(colors.base.lightGray)",
                    minHeight: "50vh",
                  })}
                  direction="horizontal"
                >
                  <Panel className={css({ p: "4" })} minSize={20}>
                    <ChatExplanation explanation={summary} />
                  </Panel>
                  <PanelResizeHandle
                    className={css({
                      display: "flex",
                      alignItems: "center",
                      width: "7px",
                      backgroundColor: "base.lightherGray",
                      opacity: 0.75,
                    })}
                  >
                    <FontAwesomeIcon
                      color={token("colors.border.base")}
                      icon={faGripLinesVertical}
                    />
                  </PanelResizeHandle>
                  <Panel
                    className={css({
                      display: "flex",
                      alignItems: result?.chartConfig?.suggestion === "TABLE" ? "start" : "center",
                    })}
                    defaultSize={60}
                    minSize={40}
                  >
                    <DynamicChartRenderer
                      currentResult={result}
                      parentDocId={parentDocId}
                      parentDocType={parentDocType}
                    />
                  </Panel>
                </PanelGroup>
              </Card>
            </VStack>
          )}
        </DialogBody>
        <DialogFooter>
          <HStack justifyContent="space-between" width="full">
            <HStack alignItems="stretch" gap={0} height="full">
              <HStack alignItems="center" gap="md" justifyContent="space-between">
                <Button
                  disabled={isLoadingContent}
                  iconLeft={faDownload}
                  onClick={handleImageDownload}
                  size="small"
                  variant="primary"
                >
                  <Trans>Download image</Trans>
                </Button>
                <Button
                  disabled={isLoadingContent}
                  iconLeft={faClipboard}
                  onClick={handleImageCopy}
                  size="small"
                  variant="primary"
                >
                  <Trans>Copy image to clipboard</Trans>
                </Button>
              </HStack>
              <Separator
                className={css({
                  marginX: "md",
                })}
                colorful={false}
                orientation="vertical"
              />
              <HStack alignItems="center" gap="md">
                <InputField
                  aria-label="Email"
                  label=""
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={_(msg`John.Doe@example.com`)}
                  size="small"
                  type="email"
                  value={email}
                />
                <Button
                  disabled={isLoadingContent || !email.length}
                  loading={loading}
                  onClick={handleShare}
                  size="small"
                  variant="primary"
                >
                  <Trans>Email result</Trans>
                </Button>
              </HStack>
            </HStack>
            <Button
              onClick={() => setIsShareResultDialogOpen(false)}
              size="small"
              variant="secondary"
            >
              <Trans>Close</Trans>
            </Button>
          </HStack>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ShareResultDialog };
