import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@components/Table";
import { Aggregations, CategoryType, ChartConfig } from "@doowii-types/chart";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { css } from "@styled-system/css";
import { HStack } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { useQuery } from "@tanstack/react-query";
import { flexRender, getCoreRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { Toggle, Tooltip } from "doowii-ui";
import { useMemo, useState } from "react";

import { fetchPageData } from "../../api/retriever";
import { ParentDocTypeEnum } from "../../api/retriever.i";
import { AggregationDropdown } from "./AggregationDropdown";
import { DataTableSkeleton } from "./DataTableSkeleton";
import { EmptyTableOverlay } from "./EmptyTableOverlay";
import { PaginationControl } from "./PaginationControl";
import { SelectionControl } from "./SelectionControl";
import { SortingControl } from "./SortingControl";
import { displayAggregationDropdown, displayBreakdownToggle } from "./utils";

interface DataTableProps {
  result: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  updateConfigState: (config: ChartConfig, updateFirestore?: boolean) => void;
}

const DataTable = ({ result, parentDocId, parentDocType, updateConfigState }: DataTableProps) => {
  const { _ } = useLingui();
  const [pagination, setPagination] = useState({
    pageSize: 50,
    pageIndex: 0,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const dataQuery = useQuery({
    queryKey: ["data", pagination, result.id, sorting, result.sql],
    queryFn: () =>
      fetchPageData({
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
        sortModel: sorting,
        docId: result.id,
        parentDocId,
        parentDocType,
        retrievalType: "query",
        userDefinedQuery: Boolean(result.originalSql && result.sql !== result.originalSql),
      }),
  });
  const defaultData = useMemo(() => [], []);

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    columns: dataQuery.data?.columns ?? [],
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: dataQuery.data?.rowCount ?? 0,
    onPaginationChange: setPagination,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
  });

  const handleAggregationChange = (columnId: string, aggregationType: Aggregations) => {
    updateConfigState(
      {
        ...result.chartConfig,
        column_aggregations: {
          ...result.chartConfig.column_aggregations,
          [columnId]: aggregationType,
        },
      },
      false
    );
  };

  const handleGroupingChange = (columnId: string) => {
    const { column_grouping } = result.chartConfig;

    // If this column is already TYPE, toggle it off
    if (column_grouping[columnId] === CategoryType.BREAKDOWN) {
      const newColumnGrouping = { ...column_grouping };
      const newBreakDownCol = Object.keys(column_grouping).find(
        (colId) => column_grouping[colId] === CategoryType.CATEGORY
      );
      newColumnGrouping[newBreakDownCol] = CategoryType.BREAKDOWN;
      newColumnGrouping[columnId] = CategoryType.CATEGORY;

      updateConfigState(
        {
          ...result.chartConfig,
          column_grouping: newColumnGrouping,
        },
        false
      );
      return;
    }

    // Find the column that currently has BREAKDOWN
    const typeColumnId = Object.keys(column_grouping).find(
      (colId) => column_grouping[colId] === CategoryType.BREAKDOWN
    );

    const newColumnGrouping = { ...column_grouping };

    // If there was a previous BREAKDOWN column, set it to CATEGORY
    if (typeColumnId) {
      newColumnGrouping[typeColumnId] = CategoryType.CATEGORY;
    }

    // Set this column to BREAKDOWN
    newColumnGrouping[columnId] = CategoryType.BREAKDOWN;

    updateConfigState(
      {
        ...result.chartConfig,
        column_grouping: newColumnGrouping,
      },
      false
    );
  };

  return (
    <div
      className={flex({
        direction: "column",
        position: "relative",
        overflowY: "auto",
        maxHeight: "3xl",
        flexGrow: 1,
        width: "full",
      })}
    >
      <Table data-testid="data-table">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id}>
                  <HStack className="column-controls" justify="space-between" textWrap="nowrap">
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {table.getRowModel().rows.length > 1 ? (
                          <HStack>
                            {displayAggregationDropdown(header.id, result.chartConfig) ? (
                              <Tooltip content={_(msg`Column Aggregation for Charting`)}>
                                <div
                                  className={css({
                                    visibility: result.chartConfig.columns.includes(
                                      header.column.id
                                    )
                                      ? "visible"
                                      : "hidden",
                                  })}
                                >
                                  <AggregationDropdown
                                    aggregationType={
                                      result?.chartConfig?.column_aggregations?.[
                                        header.column.id
                                      ] || Aggregations.SUM
                                    }
                                    onAggregationChange={(type) =>
                                      handleAggregationChange(header.column.id, type)
                                    }
                                  />
                                </div>
                              </Tooltip>
                            ) : null}
                            {displayBreakdownToggle(header.id, result.chartConfig) ? (
                              <Tooltip content={_(msg`Set as breakdown column`)}>
                                <div
                                  className={css({
                                    visibility: result.chartConfig.columns.includes(
                                      header.column.id
                                    )
                                      ? "visible"
                                      : "hidden",
                                  })}
                                >
                                  <Toggle
                                    size="xxs"
                                    pressed={
                                      result?.chartConfig?.column_grouping?.[header.column.id] ===
                                      CategoryType.BREAKDOWN
                                    }
                                    label="Breakdown"
                                    onPressedChange={() => handleGroupingChange(header.column.id)}
                                    aria-label={_(msg`Toggle Type Column`)}
                                  />{" "}
                                </div>
                              </Tooltip>
                            ) : null}

                            <SelectionControl
                              chartConfig={result.chartConfig}
                              id={header.column.id}
                              updateConfigState={updateConfigState}
                            />
                            <SortingControl
                              id={header.column.id}
                              isSorted={header.column.getIsSorted()}
                              setSorting={setSorting}
                            />
                          </HStack>
                        ) : null}
                      </>
                    )}
                  </HStack>
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.length
            ? table.getRowModel().rows.map((row) => (
                <TableRow data-state={row.getIsSelected() ? "selected" : null} key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className={css({
                        backgroundColor: result.chartConfig.columns.includes(cell.column.id)
                          ? "base.blueBrandSecondary"
                          : "inherit",
                      })}
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>

      {table.getRowModel().rows.length ? (
        <PaginationControl
          dataQuery={dataQuery}
          pagination={pagination}
          setPagination={setPagination}
          table={table}
          type={parentDocType}
        />
      ) : dataQuery.isLoading ? (
        <DataTableSkeleton />
      ) : (
        <EmptyTableOverlay />
      )}
    </div>
  );
};
export { DataTable };
