import React from "react";
import { css } from "@styled-system/css";
import { Box } from "@styled-system/jsx";
import { Text } from "doowii-ui";
import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

interface IpedsDemoInfoProps {
  onContactUsClick: () => void;
}

const IpedsDemoInfo: React.FC<IpedsDemoInfoProps> = ({ onContactUsClick }) => {
  const { _ } = useLingui();
  return (
    <Box
      className={css({
        textAlign: "center",
        color: "foreground.muted",
        textStyle: "paragraph_14",
        maxWidth: "400px",
        lineHeight: "1.5",
      })}
    >
      <Text>
        {_(msg`Take a peek at how you can use Doowii in your day-to-day work. This demo site uses the IPEDS
        dataset and is free for users with a .edu, .org, or .gov email address.`)}
      </Text>
      <br />
      <Text>
        {_(msg`For all other users,`)}{" "}
        <button
          onClick={onContactUsClick}
          className={css({
            background: "none",
            border: "none",
            color: "base.blueBrandPrimary",
            textDecoration: "underline",
            fontSize: "inherit",
            cursor: "pointer",
            padding: 0,
            lineHeight: "inherit",
            _hover: { textDecoration: "none" },
            _focus: {
              outline: "2px solid",
              outlineColor: "base.blueBrandPrimary",
            },
          })}
        >
          {_(msg` contact us`)}{" "}
        </button>{" "}
        {_(msg`for a demo.`)}
      </Text>
    </Box>
  );
};

export default IpedsDemoInfo;
