import { validateUserDefinedSQL } from "@api/retriever";
import { useChatData } from "@context/chat";
import { Trans } from "@lingui/macro";
import Editor from "@monaco-editor/react";
import { Flex, HStack } from "@styled-system/jsx";
import { Button, Text } from "doowii-ui";
import { FC, useState } from "react";
import { format } from "sql-formatter";

import { normalizeAndCompareSQL } from "../utils";

interface SQLEditorProps {
  sql: string;
  originalSql: string;
  chatId: string;
}

const SQLEditor: FC<SQLEditorProps> = ({ sql, originalSql, chatId }) => {
  const [editorSQL, setEditorSQL] = useState(format(sql, { language: "bigquery" }));
  const [sqlValidation, setSqlValidation] = useState<{ isValid: boolean | null; message: string }>({
    isValid: null,
    message: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const { updateSqlInFirestore } = useChatData();

  const updateSQL = async (sql: string) => {
    setIsUpdating(true);

    try {
      const { isValid, message } = await validateUserDefinedSQL(sql);
      setSqlValidation({ isValid, message });

      if (isValid) {
        await updateSqlInFirestore({ sql, chatId });
      }
    } catch (error) {
      console.error("Error editing SQL", error);
      setSqlValidation({ isValid: false, message: "Error editing SQL" });
    } finally {
      setIsUpdating(false);
    }
  };

  const handleChange = (value) => {
    setSqlValidation({ isValid: null, message: "" });
    setEditorSQL(value);
  };

  const focusableIds = ["reset-sql", "save-sql", "sql-diagram"];

  return (
    <Flex direction="column" gap="6" justify="space-between">
      <div data-testid="sql-editor">
        <Editor
          defaultLanguage="sql"
          defaultValue={editorSQL}
          height="80vh"
          onChange={handleChange}
          onMount={(editor, monaco) => {
            editor.onKeyDown((e) => {
              if (e.keyCode === monaco.KeyCode.Tab && e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                focusableIds.some((id) => {
                  const element = document.getElementById(id);
                  if ((element as HTMLButtonElement).disabled === false || id === "sql-diagram") {
                    (element as HTMLButtonElement).focus();
                    return true;
                  }
                });
              }
            });
          }}
          options={{ minimap: { enabled: false }, readOnly: isUpdating }}
          value={editorSQL}
        />
      </div>

      <Flex align="center" justify="space-between" p="6">
        {sqlValidation.isValid === false ? <Text>{sqlValidation.message}</Text> : <div />}
        <HStack gap="4" justify="flex-end" pr="4">
          <Button
            disabled={normalizeAndCompareSQL(editorSQL, originalSql)}
            id="reset-sql"
            onClick={() => {
              setEditorSQL(format(originalSql, { language: "bigquery" }));
              setSqlValidation({ isValid: null, message: "" });
            }}
            size="small"
            variant="secondary"
          >
            <Trans>Reset to original SQL</Trans>
          </Button>

          <Button
            disabled={
              isUpdating ||
              sqlValidation.isValid === false ||
              normalizeAndCompareSQL(editorSQL, sql)
            }
            id="save-sql"
            loading={isUpdating}
            onClick={() => updateSQL(editorSQL)}
            size="small"
            variant="primary"
          >
            <Trans>Save</Trans>
          </Button>
        </HStack>
      </Flex>
    </Flex>
  );
};

export { SQLEditor };
