import * as Sentry from "@sentry/react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionType = (...args: any[]) => Promise<any>;

export const withSentry =
  <T extends FunctionType>(asyncFunc: T) =>
  async (...args: Parameters<T>) => {
    try {
      return await asyncFunc(...args);
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTag("log-tag", "async-function-error");
        scope.setLevel("error");
        Sentry.captureMessage(`${error as string}`);
      });

      throw error;
    }
  };
