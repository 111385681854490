import { ChartConfig } from "@doowii-types/chart";
import { modifyChart } from "@services/webserver/chartModification";
import Highcharts from "highcharts";
import HighchartsAccessibility from "highcharts/modules/accessibility";

export function replacePlaceholders(config: any, replacements: { [key: string]: any }): any {
  if (typeof config === 'string') {
    // Check if the entire string is a placeholder without quotes
    const placeholderMatch = config.match(/^{{(\w+)}}$/);
    if (placeholderMatch) {
      const key = placeholderMatch[1];
      // console.log("Key:", key);
      // console.log("Replacements:", replacements[key], Object.keys(replacements)); 
      if (replacements[key] !== undefined) {
        return replacements[key]; // Return the value directly, preserving type
      } else {
        return config; // Leave as is if no replacement found
      }
    } else {
      // Replace placeholders within the string
      return config.replace(/{{(\w+)}}/g, (_, key) =>
        replacements[key] !== undefined ? String(replacements[key]) : ''
      );
    }
  } else if (Array.isArray(config)) {
    return config.map(item => replacePlaceholders(item, replacements));
  } else if (typeof config === 'object' && config !== null) {
    const newObj: any = {};
    for (const key in config) {
      newObj[key] = replacePlaceholders(config[key], replacements);
    }
    return newObj;
  } else {
    return config;
  }
}

export function parseFunctions(obj: any): any {
  if (typeof obj === 'string') {
    // Check if the string is a function
    if (
      /^function\s*\(/.test(obj.trim()) ||
      /^\(\s*function/.test(obj.trim()) ||
      /^.*=>.*$/.test(obj.trim())
    ) {
      // Evaluate the function string to a real function
      // eslint-disable-next-line no-eval
      const originalFunction = eval('(' + obj + ')');

      // Wrap the original function to catch errors during execution
      const wrappedFunction = function (...args: any[]) {
        try {
          return originalFunction.apply(this, args);
        } catch (error) {
          console.error('Error in evaluated function:', error);
          // Return a default value to prevent crashing
          return '';
        }
      };

      return wrappedFunction;
    } else {
      return obj;
    }
  } else if (Array.isArray(obj)) {
    return obj.map((item) => parseFunctions(item));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj: any = {};
    for (const key in obj) {
      newObj[key] = parseFunctions(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
}

export async function handleChartModification(userRequest: string, currentChartConfig: ChartConfig){
  try {
    const response = await modifyChart(userRequest, currentChartConfig);
    return response;
  } catch (error) {
    console.error("Error modifying chart:", error);
  }
};

export function hasOnlyOneDataPoint(transformedData) {
  if (!transformedData.series) {
    // If there's no data, consider it as having one data point (or none)
    return true;
  }

  // Check if it's a Pie Chart
  if (
    transformedData.series.length === 1 &&
    transformedData.series[0].type === "pie"
  ) {
    const dataPoints = transformedData.series[0].data;
    return dataPoints.length <= 1;
  }

  // Check if it's a Scatter Plot
  if (
    transformedData.xColumn &&
    transformedData.yColumn &&
    transformedData.series[0].type === "scatter"
  ) {
    let totalPoints = 0;
    transformedData.series.forEach((series) => {
      totalPoints += series.data.length;
    });
    return totalPoints <= 1;
  }

  // Check if it's a Bar or Line Chart
  if (
    transformedData.categories &&
    Array.isArray(transformedData.categories) &&
    transformedData.series.length > 0
  ) {
    let totalPoints = 0;
    transformedData.series.forEach((series) => {
      if (Array.isArray(series.data)) {
        totalPoints += series.data.filter(
          (value) => value !== null && value !== undefined
        ).length;
      }
    });
    return totalPoints <= 1;
  }

  // If none of the above, default to false
  return false;
}

// Initialize the accessibility module
HighchartsAccessibility(Highcharts);

const sharedChartOptions: Highcharts.Options = {
  chart: {
    zooming: {
      type: "xy" as const,
    },
    panning: {
      enabled: true,
      type: "xy" as const,
    },
    panKey: "shift" as const,
  },
};

export { Highcharts as HighchartsWithAccessibility, sharedChartOptions };