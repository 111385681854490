import { ChartConfig, ColumnType } from "@doowii-types/chart";

export const displayAggregationDropdown = (
  columnId: string,
  chartConfig: ChartConfig
) =>
  chartConfig.column_types[columnId] === ColumnType.NUMERICAL &&
  Object.keys(chartConfig.column_types).length > 1;

export const displayBreakdownToggle = (
  columnId: string,
  chartConfig: ChartConfig
) =>
  (chartConfig.column_types[columnId] === ColumnType.CATEGORICAL ||
    chartConfig.column_types[columnId] === ColumnType.TEMPORAL) &&
  Object.keys(chartConfig.column_types).length > 1 &&
  determineBreakdownDropdownCondition(chartConfig);

export const determineBreakdownDropdownCondition = (chartConfig: ChartConfig) => {
  const { columns, column_types } = chartConfig;

  const categoricalOrTemporalColumns = columns.filter(
    (col) => column_types[col] === ColumnType.CATEGORICAL || column_types[col] === ColumnType.TEMPORAL
  );

  return categoricalOrTemporalColumns.length === 2;
};
