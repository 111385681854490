import { MarkdownText } from "@components/MarkdownText";
import { css } from "@styled-system/css";

import { ChatExplanationSkeleton } from "./ChatExplanationSkeleton";

interface ChatExplanationProps {
  explanation: string;
}
const ChatExplanation = ({ explanation = "" }: ChatExplanationProps) =>
  explanation?.length === 0 ? (
    <ChatExplanationSkeleton />
  ) : (
    <div data-testid="chat-explanation">
      <MarkdownText markdownText={explanation} />
    </div>
  );

export { ChatExplanation };
