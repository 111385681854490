import { ChatExplanation } from "@components/ChatContainer";
import { useChatData } from "@context/chat";
import { Pin } from "@doowii-types/pinboard";
import { useModalClose } from "@hooks/useModalClose";
import { Flex, VisuallyHidden } from "@styled-system/jsx";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Heading,
  Separator,
} from "doowii-ui";
import { useEffect, useState } from "react";

import { FullScreenChatDataBoard } from "./FullScreenChatDataBoard";

interface Props {
  pin: Pin;
  isOpen: boolean;
  triggerId: string;
  setIsOpen: (isOpen: boolean) => void;
}
const FullScreenDialog: React.FC<Props> = ({ pin, isOpen, triggerId, setIsOpen }) => {
  useModalClose(isOpen, triggerId);
  const [summary, setSummary] = useState("");
  const { fetchSummaryFromPin } = useChatData();

  useEffect(() => {
    if (!pin.id) {
      return;
    }
    if (pin.answer) {
      setSummary(pin.answer);
      return;
    }

    (async () => {
      const chatSummary = await fetchSummaryFromPin(pin);
      setSummary(chatSummary as unknown as string);
    })();
  }, [pin, fetchSummaryFromPin]);

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogContent aria-describedby={undefined} variant="fullScreen">
        <VisuallyHidden>
          <DialogHeader>
            <DialogTitle>{pin.title}</DialogTitle>
          </DialogHeader>
        </VisuallyHidden>
        <DialogBody>
          <Flex direction="column" gap="4">
            <Heading as="h2" level={2}>
              {pin.title}
            </Heading>
            <Separator />
            <ChatExplanation explanation={summary} />
            {summary ? <Separator colorful={false} /> : null}
            <FullScreenChatDataBoard pin={pin} />
          </Flex>
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
};

export { FullScreenDialog };
