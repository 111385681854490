import { ParentDocTypeEnum } from "@api/retriever.i";
import { DataTable } from "@components/DataTable";
import { Result } from "@doowii-types/chat";
import { Pin } from "@doowii-types/pinboard";
import { DataVizTypes } from "@doowii-types/viz";
import { useChartConfig } from "@hooks/useChartConfig";
import { useChartQuery } from "@hooks/useChartQuery";
import { useResize } from "@hooks/useResize";
import { Flex } from "@styled-system/jsx";
import { useQueryClient } from "@tanstack/react-query";
import { LogoLoader } from "doowii-ui";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";

import {
  defaultBarChartOptions,
  defaultLineChartOptions,
  defaultPieChartOptions,
  defaultScatterChartOptions,
} from "./options";
import {
  hasOnlyOneDataPoint,
  HighchartsWithAccessibility,
  parseFunctions,
  replacePlaceholders,
} from "./utils";
import { ChartErrorState } from "./ChartErrorState";

interface DynamicChartRendererProps {
  currentResult: Result | Pin;
  parentDocId: string;
  parentDocType: ParentDocTypeEnum;
  isModificationMode?: boolean;
}

const DynamicChartRenderer: React.FC<DynamicChartRendererProps> = ({
  currentResult,
  parentDocId,
  parentDocType,
  isModificationMode = false,
}) => {
  const [chartOptions, setChartOptions] = useState<any>(null);
  const { height, width, componentRef } = useResize();
  const queryClient = useQueryClient();

  const {
    loadingModification,
    chartConfig,
    updateConfigState,
    tempChartConfig,
    updateOptionsToModify,
  } = useChartConfig({
    initialConfig: currentResult.chartConfig,
    chatId: currentResult.id,
    parentId: parentDocId,
    parentType: parentDocType,
  });
  const config = isModificationMode ? tempChartConfig : chartConfig;
  // Reset query when the chart type changes
  useEffect(() => {
    queryClient.resetQueries({
      queryKey: [
        "chartData",
        config.columns,
        config.suggestion,
        config.column_aggregations,
        config.column_grouping,
        currentResult.id,
        parentDocId,
        parentDocType,
      ],
      exact: true,
    });
  }, [
    config.columns,
    config.suggestion,
    config.column_aggregations,
    config.column_grouping,
    queryClient,
    currentResult.id,
    parentDocId,
    parentDocType,
  ]);

  const {
    data: chartData,
    isError,
    isLoading,
  } = useChartQuery({
    sqlQuery: currentResult.sql,
    chartConfig: config,
    docId: currentResult.id,
    parentDocId,
    type: parentDocType,
  });

  useEffect(() => {
    if (isLoading || !chartData || loadingModification) {
      return;
    }

    if (hasOnlyOneDataPoint(chartData)) {
      updateConfigState(
        {
          ...currentResult.chartConfig,
          suggestion: DataVizTypes.TABLE,
        },
        true
      ).catch((error) => {
        console.error("Error updating chart config", error);
      });
    }

    const chartType = config?.suggestion;
    let extractedData = chartData;
    let defaultOptions = {};
    switch (chartType) {
      case DataVizTypes.PIE_CHART:
        defaultOptions = defaultPieChartOptions(height, width);
        break;

      case DataVizTypes.BAR_CHART:
        defaultOptions = defaultBarChartOptions(height, width);
        break;

      case DataVizTypes.LINE_CHART:
        defaultOptions = defaultLineChartOptions(height, width);
        break;

      case DataVizTypes.SCATTER_CHART:
        defaultOptions = defaultScatterChartOptions(height, width);
        break;

      default:
        extractedData = {};
        defaultOptions = {};
    }

    const replacements = {
      height,
      width,
      ...extractedData,
    };

    const hasStoredConfig =
      config?.options?.config?.chart?.type?.toLowerCase() === chartType?.toLowerCase();

    const optionsSource = hasStoredConfig ? config?.options?.config : defaultOptions;
    const optionsWithData = replacePlaceholders(optionsSource, replacements);
    const parsedOptions = parseFunctions(optionsWithData);

    setChartOptions(parsedOptions);
    if (isModificationMode) {
      updateOptionsToModify(parsedOptions);
    }
  }, [config, chartData, isLoading, isError, height, width, loadingModification]);
  if (isError) {
    return <ChartErrorState />;
  }
  return (
    <Flex
      flexDirection="column"
      height="full"
      minHeight="xl"
      overflow="hidden"
      ref={componentRef}
      width="full"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        flexGrow={1}
        height="100%"
        justifyContent="center"
        width="100%"
      >
        {isLoading ||
        (!chartOptions && config?.suggestion != DataVizTypes.TABLE) ||
        (loadingModification && isModificationMode) ? (
          <LogoLoader size="lg" variant="infinite" />
        ) : config?.suggestion === DataVizTypes.TABLE ? (
          <DataTable
            parentDocId={parentDocId}
            parentDocType={parentDocType}
            result={currentResult}
            updateConfigState={updateConfigState}
          />
        ) : (
          // don't render chart if width or height is 0.
          // This allows the e2e tests to catch any regressions
          width > 0 &&
          height > 0 && (
            <HighchartsReact
              data-testid="highcharts-react-wrapper"
              highcharts={HighchartsWithAccessibility}
              immutable
              options={chartOptions}
            />
          )
        )}
      </Flex>{" "}
    </Flex>
  );
};

export { DynamicChartRenderer };
