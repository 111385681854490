import { fetchOrgDataSchema } from "@api/retriever";
import { PageHeader } from "@components/PageHeader";
import SQLDiagram from "@components/SQLSidePanel/SQLDiagram/SQLDiagram";
import { PATHS } from "@constants/Paths";
import { faRectangleHistory } from "@fortawesome/pro-regular-svg-icons";
import { useAuth } from "@hooks/useAuth";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Box } from "@styled-system/jsx";
import { LogoLoader, PageLayout, Text } from "doowii-ui";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * DataSchema component displays the data schema visualization.
 * It fetches the organization's data schema from retriever API
 * and displays it using the SQLDiagram component.
 */
const DataSchema: React.FC = () => {
  const { userDocument } = useAuth();
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState({});
  const [joins, setJoins] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { _ } = useLingui();
  const navigate = useNavigate();

  /**
   * Fetches the organization's data schema using the SQL query from allResults.
   * Sets the tables, joins, and columns state variables.
   * Handles errors and sets the error state if needed.
   */
  const fetchSchema = async () => {
    try {
      const { tables, joins, columns } = await fetchOrgDataSchema();
      setTables(tables);
      setJoins(joins);
      setColumns(columns);
    } catch (error) {
      console.error("Error fetching org Schema: ", error);
      setError(_(msg`Failed to load Org Schema. Please try again later.`));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSchema();
  }, [userDocument]);

  return (
    <>
      <PageHeader
        description={_(msg`visualize data schema`)}
        title={_(msg`Doowii - Data schema`)}
      />

      <PageLayout
        emptyProps={{
          title: _(msg`It’s looking a bit empty here`),
          description: _(msg`Please contact your admin to set up the data schema`),
          buttonText: _(msg`Start a chat`),
          icon: faRectangleHistory,
          onClick: () => navigate(PATHS.ROOT),
        }}
        isEmpty={tables.length === 0 && !isLoading}
        title={_(msg`Data Schema`)}
      >
        {isLoading ? (
          <Box alignContent="center" height="100%" width="100%">
            <LogoLoader variant="infinite" size="lg" />
          </Box>
        ) : error ? (
          <Text>{error}</Text>
        ) : (
          <Box backgroundColor="white" height="100%" width="100%">
            <SQLDiagram columns={columns} joins={joins} tables={tables} />
          </Box>
        )}
      </PageLayout>
    </>
  );
};

export { DataSchema };
