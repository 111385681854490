import { useChatData } from "@context/chat";
import { Trans } from "@lingui/macro";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { flex } from "@styled-system/patterns";
import { Progress, Text } from "doowii-ui";
import { useEffect, useRef, useState } from "react";

import { LogoAvatar } from "../LogoAvatar";

const useIndeterminateProgress = (isLoading: boolean) => {
  const [progress, setProgress] = useState(0);
  const animationRef = useRef<number>();

  useEffect(() => {
    if (isLoading) {
      setProgress(0);
      let startTime: number;

      const animate = (timestamp: number) => {
        if (!startTime) {
          startTime = timestamp;
        }
        const elapsedTime = timestamp - startTime;

        // Calculate progress:
        // - First 30 seconds: Linear growth from 0% to 80%
        // - From the 30th second: Logarithmic growth from 80% to 95%

        const newProgress =
          elapsedTime < 30000
            ? Math.min(80, (elapsedTime / 30000) * 80)
            : 80 + 15 * (1 - Math.exp(-(elapsedTime - 30000) / 20000));

        setProgress(newProgress);
        animationRef.current = requestAnimationFrame(animate);
      };

      animationRef.current = requestAnimationFrame(animate);
    } else {
      setProgress(100);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isLoading]);

  return progress;
};

const ChatLoading = () => {
  const { loading } = useChatData();
  const progress = useIndeterminateProgress(loading);

  return (
    <Flex
      align="center"
      className={flex({
        p: "4",
        w: "full",
        marginX: "auto",
        bg: "base.white",
        rounded: "xl",
        border: "1px solid token(colors.base.lightGray)",
        shadow: "regular",
      })}
      data-testid="chat-loading"
      direction="row"
      gap="4"
      justify="flex-start"
    >
      <LogoAvatar />

      <Text
        className={css({
          fontWeight: "medium",
          pl: "4",
          textWrap: "nowrap",
        })}
        level={3}
      >
        <Trans> Digging through the data...Thanks for your patience!</Trans>
      </Text>

      <Progress value={progress} />
    </Flex>
  );
};
export { ChatLoading };
