import { ChartConfig } from "@doowii-types/chart";
import { ChatContextI, Result, ServerResult, ThreadMap, ThreadSummary } from "@doowii-types/chat";
import { VisualizationTypesEnum } from "@doowii-types/viz";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { withSentry } from "@utils/wrapper";
import { useToast } from "doowii-ui";
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";
import { DateTime } from "luxon";
import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { fetchSuggestions } from "../../api/sequalizer";
import { useAuth } from "../../hooks/useAuth";
import { Analytics } from "../../services/analytics/Analytics";
import {
  addToChatHistory,
  db,
  fetchAndUpdateChartConfig,
  getSourceType,
  incrementUsageMetrics,
  updateTitleInThreadRoutine,
} from "../../services/firebase";
import { reportNegativeFeedback } from "../../services/webserver/feedback";
import { FirebaseTimestamp, Pin } from "../../types/pinboard";

const initialState: ChatContextI = {
  currentResult: null,
  setCurrentResult: async (result: Result | null) => {},
  searchHistory: [],
  setSearchHistory: async () => {},
  allResults: [],
  setAllResults: async () => {},
  answer: [],
  setAnswer: async () => {},
  threads: [],
  setThreads: async () => {},
  currentThread: "",
  setCurrentThread: () => {},
  chatCount: 0,
  deleteThread: async (documentId: string) => {},
  selectThread: async () => {},
  newChat: () => "",
  dynamicCache: [],
  setDynamicCache: async () => {},
  updateSatisfaction: async () => {},
  updateIndividualSatisfaction: async () => {},
  updateTitle: async () => {},
  searchQuery: "",
  setSearchQuery: async () => {},
  searchResults: [],
  setSearchResults: async () => {},
  loadingSearchResults: false,
  setLoadingSearchResults: async () => {},
  filteredThreads: [],
  loadingThread: false,
  setLoadingThread: async () => {},
  navigateToChatFromPin: async () => {},
  fetchSummaryFromPin: async () => {},
  updateSqlInFirestore: async ({ sql, chatId }: { sql: string; chatId: string }) => {},
  updateChartConfigInFirestore: async (chartConfig: ChartConfig, chatId: string) => {},
  fetchingSuggestions: false,
  loading: false,
  setLoading: async () => {},
  deleteChatFromThread: async () => {},
  updateResultsFields: (updates: Partial<Result>) => {},
  streamLoading: false,
  setStreamLoading: async () => {},
  refetchSuggestions: () => {},
  isChatAvailable: async (): Promise<boolean> => false,
};
const ChatDataContext = createContext(initialState);

export const useChatData = () => useContext(ChatDataContext);

export const ChatDataProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { _ } = useLingui();
  const [currentResult, setCurrentResult] = useState<Result | null>(null);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(null);
  const [chatCount, setChatCount] = useState(0);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [allResults, setAllResults] = useState<Result[]>([]);
  const [answer, setAnswer] = useState<string[]>([]);
  const [threads, setThreads] = useState<ThreadSummary[]>([]);
  const [currentThread, setCurrentThread] = useState<string>(uuidv4());
  const [dynamicCache, setDynamicCache] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<string[]>([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [chatThreadMap, setChatThreadMap] = useState<ThreadMap[]>([]);
  const [filteredThreads, setFilteredThreads] = useState<ThreadSummary[]>([]);
  const [loadingThread, setLoadingThread] = useState(false);
  const [fetchingSuggestions, setFetchingSuggestions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [streamLoading, setStreamLoading] = useState(false);

  const { authUser, userDocument } = useAuth();
  const { toast } = useToast();

  const pageSize = 50;

  const newChat: () => string = () => {
    const newId = uuidv4();
    clearState();
    setCurrentThread(newId);
    return newId;
  };

  const firebaseTimestampToLuxon = (firebaseTimestamp: FirebaseTimestamp) => {
    const asNumbers = firebaseTimestamp.seconds * 1000 + firebaseTimestamp.nanoseconds / 1000000;

    return DateTime.fromMillis(asNumbers);
  };

  useEffect(() => {
    if (
      !currentResult ||
      currentResult.type !== "PREDICTION" ||
      ["success", "error"].includes(currentResult.status)
    ) {
      return; // Exit if no need to set up a listener
    }

    const setupDocListener = async () => {
      const docRef = doc(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats",
        currentResult.id
      );

      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        await addToChatHistory(
          threads,
          currentThread,
          userDocument.organization,
          authUser.uid,
          searchHistory[searchHistory.length - 1],
          allResults[allResults.length - 1],
          answer[answer.length - 1]
        );
      }

      // Directly declare unsubscribe within the useEffect hook
      const unsubscribe = onSnapshot(docRef, async (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const activeThread = threads.find((thread) => thread.id === currentThread);
          if (activeThread.title === "New Chat") {
            await updateTitleInThreadRoutine(userDocument.organization, currentThread);
          }

          if (["success", "error"].includes(data.status)) {
            data.timestamp = firebaseTimestampToLuxon(data.timestamp);
            setCurrentResult((prev) => ({ ...prev, ...data }));

            // Assuming there's a method like setAllResults to update context
            setAllResults((prevResults) =>
              prevResults.map((result) =>
                result.id === currentResult.id ? { ...result, ...data } : result
              )
            );

            setAnswer(
              answer.map((item, index) =>
                index === allResults.findIndex((elem) => elem.id === currentResult.id)
                  ? data.answer
                  : item
              )
            );

            unsubscribe();
          }
        } else {
          console.error("Document does not exist.");
        }
      });

      // Return a cleanup function directly from useEffect
      return () => unsubscribe();
    };

    setupDocListener();
  }, [currentResult, userDocument]);

  useEffect(() => {
    if (userDocument && userDocument.organization) {
      const chatThreadMappingRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "chat_thread_mapping"
      );

      const q = query(chatThreadMappingRef, where("created_by", "==", userDocument.id));

      const unsubscribe = onSnapshot(q, (snapshot: QuerySnapshot): void => {
        const updatedChatThreadMap = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<ThreadMap, "id">),
        }));

        setChatThreadMap(updatedChatThreadMap);
        fetchDynamicCache(updatedChatThreadMap.map((item) => item.query));
      });

      return () => unsubscribe();
    }
  }, [userDocument]);

  const fetchThreads = () => {
    try {
      if (!userDocument || !userDocument.organization) {
        throw new Error("User document not found");
      }

      const org = userDocument.organization;
      const threadsCollectionRef = collection(db, "organizations", org, "threads");

      // Added a where clause to filter documents based on created_by field
      const threadsQuery = query(
        threadsCollectionRef,
        where("created_by", "==", userDocument.id),
        orderBy("created_at", "desc")
      );

      // Using onSnapshot for real-time updates
      // Return the unsubscribe function to allow for cleanup
      return onSnapshot(threadsQuery, (threadsSnapshot) => {
        const fetchedThreads: ThreadSummary[] = [];
        threadsSnapshot.forEach((doc) => {
          fetchedThreads.push(doc.data() as ThreadSummary);
        });
        setThreads(fetchedThreads);
      });
    } catch (e) {
      console.error("Error fetch threads:", e);
    }
  };

  const deleteThread = withSentry(async (documentId) => {
    try {
      if (userDocument && userDocument.organization) {
        const org = userDocument.organization;
        const threadRef = doc(db, "organizations", org, "threads", documentId);
        await deleteDoc(threadRef);

        //Delete all the notifications for the thread
        const notificationsRef = collection(db, "organizations", org, "notifications");
        const notificationQuery = query(notificationsRef, where("thread_id", "==", documentId));
        const querySnapshot = await getDocs(notificationQuery);
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref);
        });

        setThreads(threads.filter((thread) => thread.id !== documentId));
        if (documentId === currentThread) {
          setCurrentThread(uuidv4());
          clearState();
        }
      }
    } catch (error) {
      console.error("Failed to delete thread:", error);

      throw error;
    }
  });

  const clearState = () => {
    setAllResults([]);
    setAnswer([]);
    setChatCount(0);
  };

  const populateData = (data: ServerResult) => {
    if (data.type === "PREDICTION") {
      return {
        id: data.docId,
        query: data.query,
        title: data.title,
        satisfied: data.satisfied,
        error: data.error,
        sql: data.sql ?? data.prediction_sql,
        timestamp: firebaseTimestampToLuxon(data.timestamp),
        originalSql: data.originalSql ?? data.sql ?? data.prediction_sql,
        status: data.status ?? "success",
        type: data.type ?? "QUERY",
        follow_up_prompts: data.follow_up_prompts || [],
        latency: data.latency ?? 0,
        chartConfig: data.chart_config,
      } as Result;
    } else {
      return {
        id: data.docId,
        query: data.query,
        title: data.title,
        satisfied: data.satisfied,
        error: data.error,
        sql: data.sql ?? data.prediction_sql,
        timestamp: firebaseTimestampToLuxon(data.timestamp),
        originalSql: data.originalSql ?? data.sql ?? data.prediction_sql,
        status: data.status ?? "success",
        type: data.type ?? "QUERY",
        follow_up_prompts: data.follow_up_prompts || [],
        latency: data.latency ?? 0,
        chartConfig: data.chart_config,
      } as Result;
    }
  };

  const selectThread = withSentry(
    async (threadId: string, setLatestResult: string | null = null) => {
      clearState();
      setCurrentThread(threadId); // Set the current thread ID
      setChatCount(threads.filter((thread) => thread.id === threadId)[0].chatCount);
      setLoadingThread(true);

      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User document not found");
        }

        const chatsRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          threadId,
          "chats"
        );

        const firstChatsQuery = query(chatsRef, orderBy("timestamp", "desc"));
        const chatSnapshot = await getDocs(firstChatsQuery);
        const lastVisible = chatSnapshot.docs[chatSnapshot.docs.length - 1];
        setLastVisible(lastVisible);

        const chats = {
          answer: [] as string[],
          question: [] as string[],
          result: [] as Result[],
        };
        chatSnapshot.forEach(async (doc) => {
          const docData = doc.data();
          const result = populateData(docData as ServerResult);
          if (result) {
            chats.answer.push(docData.answer);
            chats.result.push(result);
          }
        });

        const { answer, result } = chats;

        if (result.length !== 0) {
          setAllResults(result.reverse());
        }

        if (answer.length !== 0) {
          setAnswer(answer.reverse());
        }
      } catch (error) {
        console.error("Error fetching chats for thread:", error);

        throw error;
      } finally {
        setLoadingThread(false);
      }
    }
  );

  const navigateToChatFromPin = withSentry(async (chatId: string) => {
    const chatsRef = collectionGroup(db, "chats");
    const chatQuery = query(chatsRef, where("docId", "==", chatId));
    const chatSnapshot = await getDocs(chatQuery);

    try {
      chatSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.thread_id) {
          throw data.thread_id;
        }
      });
    } catch (threadId) {
      await selectThread(threadId);
      return threadId;
    }

    return null;
  });

  const fetchSummaryFromPin = withSentry(async (pin: Pin) => {
    if (!userDocument?.organization) {
      console.error("User document or organization not found");
      return null;
    }

    try {
      const chatsRef = collectionGroup(db, "chats");
      const chatQuery = query(chatsRef, where("docId", "==", pin.id));
      const chatSnapshot = await getDocs(chatQuery);

      for (const doc of chatSnapshot.docs) {
        const data = doc.data();
        if (data.answer) {
          return data.answer;
        }
      }
    } catch (e) {
      console.error("Error fetching chat summary", e);
      toast({
        status: "error",
        title: _(msg`Sorry, we couldn't fetch the summary for this pin`),
      });

      return null;
    }
  });

  const fetchDynamicCache = withSentry(async (previousQuestions = []) => {
    try {
      setFetchingSuggestions(true);
      const sourceTypes = await getSourceType(userDocument?.organization);

      const suggestedQuestions = await fetchSuggestions(
        userDocument?.organization,
        previousQuestions,
        sourceTypes,
        "query"
      );

      if (suggestedQuestions) {
        setDynamicCache(suggestedQuestions);
      }
    } catch (e) {
      throw e;
    } finally {
      setFetchingSuggestions(false);
    }
  });

  const refetchSuggestions = useCallback(() => {
    if (userDocument && userDocument.organization) {
      fetchDynamicCache([]);
    }
  }, [userDocument]);

  const updateTitle = withSentry(async (title: string) => {
    try {
      if (!userDocument || !userDocument.organization) {
        throw new Error("User document not found");
      }

      const chatHistoryCollectionRef = collection(
        db,
        "organizations",
        userDocument.organization,
        "threads",
        currentThread,
        "chats"
      );
      const updatedData = {
        title,
      };

      const docToUpdate = doc(chatHistoryCollectionRef, currentResult.id);
      await updateDoc(docToUpdate, updatedData);
      updateResultsFields(updatedData);
      toast({
        status: "success",
        title: _(msg`Title updated successfully!`),
      });
    } catch (e) {
      console.error("Error updating chat record: ", e);
      toast({
        status: "error",
        title: _(msg`Failed to update title. Please try again later.`),
      });

      throw e;
    }
  });
  const updateSqlInFirestore = withSentry(
    async ({ sql, chatId }: { sql: string; chatId: string }) => {
      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User document not found");
        }

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );

        const docToUpdate = doc(chatHistoryCollectionRef, chatId);
        const chatDoc = await getDoc(docToUpdate);

        if (!chatDoc.exists()) {
          throw new Error("Chat document does not exist");
        }

        const chatData = chatDoc.data() as ServerResult;

        const updatedData: Partial<Result> = {};
        if (!chatData.originalSql) {
          updatedData.originalSql = chatData.sql;
        }
        updatedData.sqlHistory = chatData.sqlHistory
          ? [...chatData.sqlHistory, chatData.sql]
          : [chatData.sql];
        updatedData.sql = sql;
        await updateDoc(docToUpdate, updatedData);
        const updateFields = {
          sql,
          sqlHistory: updatedData.sqlHistory,
          originalSql: chatData.originalSql ? chatData.originalSql : chatData.sql,
        };

        const query = allResults.find((result) => result.id === chatId)?.query ?? "";
        const updatedConfig = await fetchAndUpdateChartConfig(
          userDocument.organization,
          currentThread,
          chatId,
          query,
          sql
        );
        // @ts-expect-error
        updateFields.chartConfig = updatedConfig;

        updateResultsFields(updateFields, chatId);
        await incrementUsageMetrics(userDocument.organization);

        toast({
          status: "success",
          title: _(msg`SQL updated successfully!`),
        });
      } catch (e) {
        console.error("Error updating SQL in chat record: ", e);
        toast({
          status: "error",
          title: _(msg`Failed to update SQL. Please try again later.`),
        });

        throw e;
      }
    }
  );

  const updateChartConfigInFirestore = withSentry(
    async (chartConfig: ChartConfig, chatId?: string) => {
      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User document not found");
        }

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );

        const docToUpdate = doc(chatHistoryCollectionRef, chatId || currentResult?.id);
        const chatDoc = await getDoc(docToUpdate);

        if (!chatDoc.exists()) {
          throw new Error("Chat document does not exist");
        }

        await updateDoc(docToUpdate, {
          chart_config: chartConfig,
        });

        const updatedResults = allResults.map((result) => {
          if (result.id === currentResult?.id) {
            return {
              ...result,
              chartConfig,
            };
          }
          return result;
        });
        setAllResults(updatedResults);

        toast({
          status: "success",
          title: _(msg`Chart updated successfully!`),
        });
      } catch (e) {
        console.error("Error updating chart config in chat record: ", e);
        toast({
          status: "error",
          title: _(msg`Failed to update chart. Please try again later.`),
        });

        throw e;
      }
    }
  );

  const updateSatisfaction = withSentry(
    async (satisfaction: boolean, feedback: string = "", sendNotification: boolean = false) => {
      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User document not found");
        }

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );
        const newSatisfaction = { like: satisfaction, feedback };

        const updatedData = {
          satisfied: newSatisfaction,
        };
        const docToUpdate = doc(chatHistoryCollectionRef, currentResult.id);
        await updateDoc(docToUpdate, updatedData);
        updateResultsFields(updatedData);

        if (sendNotification) {
          await reportNegativeFeedback(currentResult.query, currentResult.id);
        }

        Analytics.track("Gave Feedback", { satisfaction });
      } catch (e) {
        console.error("Error updating chat record: ", e);

        throw e;
      }
    }
  );

  const updateIndividualSatisfaction = withSentry(
    async (
      index: number,
      satisfaction: boolean,
      feedback: string = "",
      sendNotification: boolean = false
    ) => {
      try {
        if (!userDocument || !userDocument.organization) {
          throw new Error("User document not found");
        }

        const newSatisfaction = { like: satisfaction, feedback };
        allResults[index].satisfied = newSatisfaction;
        const updatedData = {
          satisfied: newSatisfaction,
        };

        const chatHistoryCollectionRef = collection(
          db,
          "organizations",
          userDocument.organization,
          "threads",
          currentThread,
          "chats"
        );
        const docToUpdate = doc(chatHistoryCollectionRef, allResults[index].id);
        await updateDoc(docToUpdate, updatedData);
        if (sendNotification) {
          await reportNegativeFeedback(allResults[index].query, allResults[index].id);
        }

        setAllResults([...allResults]);
      } catch (e) {
        console.error("Error updating chat record: ", e);

        throw e;
      }
    }
  );

  const searchThreads = () => {
    if (!searchQuery) {
      setFilteredThreads(threads);
      return;
    }

    const lowercasedQuery = searchQuery.toLowerCase();
    const relevantThreadMappings = chatThreadMap.filter((item) =>
      item.query.toLowerCase().includes(lowercasedQuery)
    );

    const relevantThreadsIds = new Set(relevantThreadMappings.map((item) => item.thread_id));
    const fThreads = threads
      .filter((thread) => relevantThreadsIds.has(thread.id))
      .map((thread) => {
        const threadMapping: ThreadMap | undefined = relevantThreadMappings.find(
          (item) => item.thread_id === thread.id
        );

        if (!threadMapping) {
          return {
            ...thread,
            resultId: null,
            question: "",
          };
        }

        return {
          ...thread,
          resultId: threadMapping.id,
          question: threadMapping.query,
        };
      });

    setFilteredThreads(fThreads);
  };

  const deleteChatFromThread = withSentry(async (chatId, navigateToThreads) => {
    try {
      toast({
        status: "info",
        title: _(msg`Deleting Chat`),
      });
      const chatsRef = collectionGroup(db, "chats");
      const chatQuery = query(chatsRef, where("docId", "==", chatId));
      const chatSnapshot = await getDocs(chatQuery);

      chatSnapshot.forEach(async (chatDoc) => {
        const threadId = chatDoc.data().thread_id;
        await deleteDoc(chatDoc.ref);

        const threadRef = doc(db, "organizations", userDocument.organization, "threads", threadId);

        const chatIndex = allResults.findIndex((item) => item.id === chatId);
        const updatedResults = allResults.filter((item) => item.id !== chatId);
        const updatedAnswers = answer.filter((item, index) => index !== chatIndex);
        await updateDoc(threadRef, { chatCount: updatedResults.length });
        setAllResults(updatedResults);
        setAnswer(updatedAnswers);
        if (updatedResults.length === 0) {
          await deleteThread(chatDoc.data().thread_id);
          navigateToThreads();
        }
      });

      toast({
        status: "success",
        title: _(msg`Chat deleted successfully!`),
      });
    } catch (e) {
      console.error("Error deleting chat: ", e);

      toast({
        status: "error",
        title: _(msg`Error deleting chat!`),
      });

      throw e;
    }
  });

  const updateResultsFields = (updates: Partial<Result>, chatId?: string) => {
    const updatedAllResults = allResults.map((result) =>
      result.id === chatId ? { ...result, ...updates } : result
    );

    setAllResults(updatedAllResults);
  };

  const isChatAvailable = async (chatId: string): Promise<boolean> => {
    const chatsRef = collectionGroup(db, "chats");
    const chatQuery = query(chatsRef, where("docId", "==", chatId));

    try {
      const chatSnapshot = await getDocs(chatQuery);
      return !chatSnapshot.empty;
    } catch (error) {
      console.error("Error checking chat availability:", error);
      return false;
    }
  };

  useEffect(() => {
    searchThreads();
  }, [searchQuery, chatThreadMap, threads]);

  useEffect(() => {
    let unsubscribe: Unsubscribe; // Initialize to a no-op function
    if (userDocument && userDocument.organization) {
      unsubscribe = fetchThreads()!; // Call fetchThreads and get the unsubscribe function
    } else {
      unsubscribe = () => {};
    }
    // Cleanup the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [userDocument]);

  const value = {
    currentResult,
    setCurrentResult,
    searchHistory,
    setSearchHistory,
    allResults,
    setAllResults,
    answer,
    setAnswer,
    threads,
    setThreads,
    currentThread,
    setCurrentThread,
    deleteThread,
    selectThread,
    chatCount,
    newChat,
    dynamicCache,
    setDynamicCache,
    updateSatisfaction,
    updateIndividualSatisfaction,
    updateTitle,
    searchQuery,
    setSearchQuery,
    searchResults,
    setSearchResults,
    loadingSearchResults,
    setLoadingSearchResults,
    filteredThreads,
    loadingThread,
    setLoadingThread,
    navigateToChatFromPin,
    fetchSummaryFromPin,
    updateSqlInFirestore,
    updateChartConfigInFirestore,
    fetchingSuggestions,
    loading,
    setLoading,
    deleteChatFromThread,
    updateResultsFields,
    streamLoading,
    setStreamLoading,
    refetchSuggestions,
    isChatAvailable,
  };

  return <ChatDataContext.Provider value={value}>{children}</ChatDataContext.Provider>;
};
